import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import LandingPage from './pages/LandingPage';
import ProtectedRoute from './components/auth/ProtectedRoute';
import DashboardForm from './pages/DashboardForm';
import DashboardListingApplicants from './pages/DashboardListingApplicants';
import Download from './pages/Download';
import ThankYouPage from './pages/ThankYouPage'
import ApplicationStatusPage from './pages/ApplicationStatusPage';
import { Toaster } from 'react-hot-toast';
import { LanguageProvider } from './context/LanguageContext';

export default function App() {
  return (
    <LanguageProvider>
      <Router>
        <Toaster/>
        <div className="min-h-screen bg-[#EAECED] flex flex-col">
          <Routes>
            <Route path="/" element={<Navigate to="/landing" />} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/download" element={<Download />} />
            <Route 
              path="/thank-you" 
              element={<ProtectedRoute role="user" requireFormSubmission={true}><ThankYouPage /></ProtectedRoute>} 
            />
            <Route 
              path="/dashboard/form" 
              element={<ProtectedRoute role="user" requireNoFormSubmission={true}><DashboardForm /></ProtectedRoute>} 
            />
            <Route path="/dashboard/listing-applicants" element={<ProtectedRoute role="admin"><DashboardListingApplicants /></ProtectedRoute>} />
            <Route path="/status/:status" element={<ProtectedRoute role="user"><ApplicationStatusPage /></ProtectedRoute>} />
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
}