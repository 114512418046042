import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import api from '../utils/api';
import LoadingOverlay from '../components/loader/LoadingOverlay';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isFormValid = email && password;
  const isLoginPage = location.pathname === '/login';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.post('/api/signin', { email, password });
      if (response.success) {
        const user = { 
          email: response.data.email, 
          role: response.data.user_type === 1 ? 'admin' : 'user', 
          token: response.data.token,
          is_app_submit: response.data.is_app_submit,
          application_status: response.data.applicaiton_status
        };
        localStorage.setItem('user', JSON.stringify(user));
        toast.success(response.message);
  
        if (user.role === 'admin') {
          navigate('/dashboard/listing-applicants');
        } else if (user.is_app_submit === 0 && user.application_status === 'Not-submitted') {
          navigate('/dashboard/form');
        } else if (user.is_app_submit === 1 && user.application_status === 'Pending') {
          navigate('/thank-you');
        } else if (user.is_app_submit === 1 && (user.application_status === 'Accepted' || user.application_status === 'Rejected')) {
          navigate(`/status/${user.application_status.toLowerCase()}`);
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="min-h-screen flex">
      <div className="hidden md:flex md:w-2/5 bg-no-repeat bg-cover relative" style={{ backgroundImage: 'url(/bg_form_img.png)' }}>
        <div className="bg-[#031122] text-white p-4 flex items-center fixed w-full z-50">
        {isLoginPage && (
            <Link to="/landing" className="flex items-center">
              <img src="/logo.svg" alt="Logo" className="h-8" />
            </Link>
          )}        
          </div>
        <div className="absolute top-0 left-0 text-white p-4 pt-16 overflow-y-auto h-[calc(100vh-1rem)] w-full z-40 custom-scrollbar pb-16">
          <h1 className="text-2xl lg:text-4xl font-bold">
            Welcome to the Digital Media & Marketing Syndicate of Lebanon
          </h1>
          <p className="mt-4 text-sm leading-tight">
            Dear Future Members,
            <br /><br />
            On behalf of the Digital Media & Marketing Syndicate, I am delighted to welcome you to our evolving digital platform. As we embark on this exciting journey, we invite you to join our vibrant community of innovators, thinkers, and creators who are shaping the future of digital media and marketing in Lebanon and beyond.
            <br /><br />
            We are thrilled to announce that those who become members of our syndicate will gain exclusive access to our first-in-class virtual reality premises. This unique space will enable you to learn and engage in the metaverse, connecting with top talented trainers, mentors, coaches, motivational speakers, and some of the best global experts in the field. This immersive learning experience will allow you to transcend traditional boundaries and participate in a dynamic environment that fosters creativity and innovation.
            <br /><br />
            While our website is currently under construction, we encourage you to fill out our membership application. Your application will allow our management team to assess your suitability within our dynamic syndicate, and we will provide you with timely feedback. By becoming a member, you will gain exclusive access to invaluable insights into the ever-evolving landscape of digital media and marketing, along with unique services designed to enhance your learning and professional growth.
            <br /><br />
            <strong>What We Offer:</strong>
            <ul className="list-disc list-inside ml-4">
              <li>Continuous Education Programs</li>
              <li>Entrepreneurship Boot Camp</li>
              <li>Virtual Reality Learning and Networking Experience</li>
              <li>Innovation Lab</li>
              <li>Access to Skilled Networks</li>
              <li>Strategic Investing Partners and Legal Affairs Consultancy</li>
            </ul>
            <br />
            We believe that the future of digital media and marketing is collaborative, and we are excited to support your growth within our syndicate. Together, we can push the boundaries of what is possible and create a vibrant community that thrives on innovation.
            <br /><br />
            Thank you for considering membership with the Digital Media & Marketing Syndicate. We look forward to welcoming you aboard and embarking on this journey together.
            <br /><br />
            <strong>Warm regards,</strong>
            <br />
            <strong>Patrick Abou Chacra</strong>
            <br />
            <strong>President, Digital Media & Marketing Syndicate of Lebanon</strong>
          </p>
        </div>
      </div>
      
      <div className="w-full md:w-3/5 bg-[#0a223f] flex items-center justify-center p-4">
        <div className="max-w-md w-full p-8">
          <h1 className="text-3xl font-bold mb-8 text-center text-white">Sign In</h1>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-white mb-2">
                Enter Your Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="mt-1 block w-full rounded-lg border border-white shadow-sm p-4 bg-transparent text-white focus:outline-none"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-white mb-2">
                Enter Your Password <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className="mt-1 block w-full rounded-lg border border-white shadow-sm p-4 bg-transparent text-white focus:outline-none"
                placeholder="Enter Your Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <p className="text-sm font-light text-white text-center">
              Don’t have an account yet? <Link to="/signup" className="font-medium text-primary-600 hover:underline">Sign up</Link>
            </p>
            <div className="flex justify-center">
              <button
                type="submit"
                className={`bg-yellow-500 text-white py-3 px-16 rounded-full shadow-sm hover:bg-yellow-600 ${!isFormValid || isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={!isFormValid || isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center space-x-2">
                    <span className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5"></span>
                    <span>Loading...</span>
                  </div>
                ) : (
                  'Sign In'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      
      <Toaster />
      <LoadingOverlay isActive={isLoading} />
    </div>
  );
}