import React, { useState, useRef, useEffect } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { toast } from 'react-hot-toast';
import api from '../utils/api';
import LoadingOverlay from '../components/loader/LoadingOverlay';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';

export default function DashboardForm() {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [formData, setFormData] = useState({
    full_name_arabic: '',
    // full_name_english: 'dummy',
    age_in_year: '',
    gender: '',
    nationality: [],
    permanent_residence: 'اختر القضاء',
    current_occupation: [],
    institute_name: '',
    certificate: [],
    specialization: '',
    experience: 'اختر الخبرة',
    condition_question: [],
    is_union_member: '',
    union_belong: '',
    phone_no: '',
    linkedin: '',
    instagram: '',
    facebook: '',
    youtube_channel: '',
    refrence_name: '',
    refrence_phone_no: '',
    attach_file: null,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const audio = new Audio('/song.mp3');
    audio.loop = true;
    
    const playAudio = async () => {
      try {
        await audio.play();
      } catch (error) {
        console.error('Audio play failed:', error);
      }
    };

    playAudio();

    return () => {
      if (!audio.paused) {
        audio.pause();
      }
      audio.currentTime = 0;
    };
  }, []);

  const validateForm = () => {
    let formErrors = {};
    
    if (!formData.full_name_arabic.trim()) formErrors.full_name_arabic = "This is required";
    if (formData.age_in_year.length === 0) formErrors.age_in_year = "This is required";
    if (formData.nationality.length === 0) formErrors.nationality = "This is required";
    if (!formData.permanent_residence.trim()) formErrors.permanent_residence = "This is required";
    if (formData.current_occupation.length === 0) formErrors.current_occupation = "This is required";
    if (!formData.institute_name.trim()) formErrors.institute_name = "This is required";
    if (formData.certificate.length === 0) formErrors.certificate = "This is required";
    if (!formData.specialization.trim()) formErrors.specialization = "This is required";
    if (!formData.experience.trim()) formErrors.experience = "This is required";
    if (formData.condition_question.length === 0) formErrors.condition_question = "This is required";
    if (!formData.is_union_member.trim()) formErrors.is_union_member = "This is required";
    if (!formData.phone_no.trim()) formErrors.phone_no = "This is required";
    if (!formData.gender.trim()) formErrors.gender = "This is required";
    if (!formData.linkedin.trim()) formErrors.linkedin = "This is required";
    if (!formData.instagram.trim()) formErrors.instagram = "This is required";
    if (!formData.facebook.trim()) formErrors.facebook = "This is required";
    if (!formData.refrence_name.trim()) formErrors.refrence_name = "This is required";
    if (!formData.refrence_phone_no.trim()) formErrors.refrence_phone_no = "This is required";
    if (!formData.image) formErrors.image = "This is required";
    if (!formData.attach_file || formData.attach_file.length === 0) formErrors.attach_file = "This is required";
  
    setErrors(formErrors);
  
    if (Object.keys(formErrors).length > 0) {
      toast.error("Please fill all the required fields");
      return false;
    }
  
    return true;
  };
   
  
  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
  
    if (type === 'checkbox') {
      if (name === 'gender') {
        if (checked) {
          setFormData((prev) => ({
            ...prev,
            gender: value,
          }));
        }
      } else if (name === 'nationality') {
        if (checked) {
          setFormData((prev) => ({
            ...prev,
            nationality: [value],
          }));
        }
      } else if (name === 'age_in_year') {
        if (checked) {
          setFormData((prev) => ({
            ...prev,
            age_in_year: [value],
          }));
        }
      } else {
        if (checked) {
          setFormData((prev) => ({
            ...prev,
            [name]: [...prev[name], value],
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            [name]: prev[name].filter((item) => item !== value),
          }));
        }
      }
    } else if (type === 'file') {
      if (name === 'image') {
        setFormData((prev) => ({
          ...prev,
          [name]: files[0],
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: Array.from(files),
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  
    if (value.trim() !== '') {
      setErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) return;
  
    setIsLoading(true);
  
    const data = new FormData();
    for (const key in formData) {
      if (Array.isArray(formData[key])) {
        if (key === 'attach_file') {
          formData[key].forEach((file) => data.append(`${key}[]`, file));
        } else {
          formData[key].forEach((item) => data.append(`${key}[]`, item));
        }
      } else {
        data.append(key, formData[key]);
      }
    }
  
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.token;
  
    try {
      const response = await api.post('/api/saveUserApp', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.success) {
        toast.success('Form Submitted Successfully');
        
        const updatedUser = { ...user, is_app_submit: 1 };
        localStorage.setItem('user', JSON.stringify(updatedUser));
  
        setTimeout(() => {
          navigate('/thank-you');
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };
  
  



  return (
    <DashboardLayout role="user" heading="User Form">
      <div className="flex flex-col lg:flex-row min-h-screen overflow-hidden">
      <div className="relative lg:w-1/3 w-full">
          <img 
            src="/bg_form_img.png" 
            alt="Background" 
            className="w-full h-32 lg:h-full object-cover lg:absolute lg:top-0 lg:left-0"
          />
          <div className="lg:absolute lg:top-0 lg:left-0 lg:text-white lg:overflow-y-auto lg:h-[calc(100vh-2rem)] lg:p-4 custom-scrollbar">
            <h1 className="text-2xl lg:text-4xl font-bold hidden lg:block">
              Welcome to the Digital Media & Marketing Syndicate of Lebanon
            </h1>
            <p className="mt-4 text-sm leading-tight hidden lg:block">
              Dear Future Members,
              <br /><br />
              On behalf of the Digital Media & Marketing Syndicate, I am delighted to welcome you to our evolving digital platform. As we embark on this exciting journey, we invite you to join our vibrant community of innovators, thinkers, and creators who are shaping the future of digital media and marketing in Lebanon and beyond.
              <br /><br />
              We are thrilled to announce that those who become members of our syndicate will gain exclusive access to our first-in-class virtual reality premises. This unique space will enable you to learn and engage in the metaverse, connecting with top talented trainers, mentors, coaches, motivational speakers, and some of the best global experts in the field. This immersive learning experience will allow you to transcend traditional boundaries and participate in a dynamic environment that fosters creativity and innovation.
              <br /><br />
              While our website is currently under construction, we encourage you to fill out our membership application. Your application will allow our management team to assess your suitability within our dynamic syndicate, and we will provide you with timely feedback. By becoming a member, you will gain exclusive access to invaluable insights into the ever-evolving landscape of digital media and marketing, along with unique services designed to enhance your learning and professional growth.
              <br /><br />
              <strong>What We Offer:</strong>
              <ul className="list-disc list-inside ml-4">
                <li>Continuous Education Programs</li>
                <li>Entrepreneurship Boot Camp</li>
                <li>Virtual Reality Learning and Networking Experience</li>
                <li>Innovation Lab</li>
                <li>Access to Skilled Networks</li>
                <li>Strategic Investing Partners and Legal Affairs Consultancy</li>
              </ul>
              <br />
              We believe that the future of digital media and marketing is collaborative, and we are excited to support your growth within our syndicate. Together, we can push the boundaries of what is possible and create a vibrant community that thrives on innovation.
              <br /><br />
              Thank you for considering membership with the Digital Media & Marketing Syndicate. We look forward to welcoming you aboard and embarking on this journey together.
              <br /><br />
              <strong>Warm regards,</strong>
              <br />
              <strong>Patrick Abou Chacra</strong>
              <br />
              <strong>President, Digital Media & Marketing Syndicate of Lebanon</strong>
            </p>
          </div>
          <div className="lg:hidden absolute top-0 left-0 w-full text-center p-4 text-white bg-black bg-opacity-60">
            <h1 className="text-2xl font-bold">
              Welcome
            </h1>
          </div>
        </div>
        <div className="lg:w-2/3 w-full p-4 lg:p-8 bg-[#0a223f] flex items-center justify-center overflow-hidden">
          <div className="max-w-4xl w-full h-full">
          <h1 className="text-3xl font-bold mb-8 text-center text-white">
            {language === 'ar' ? 'طلب انتساب الى نقابة الاعلان والتسويق الرقمي في لبنان' : 'Application to join the Digital Media & Marketing Syndicate in Lebanon'}
          </h1>
            <form className="space-y-6 overflow-y-scroll h-[calc(100vh-20rem)] lg:h-[calc(100vh-12rem)] custom-scrollbar p-4 md:p-8" onSubmit={handleSubmit}>
            <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'الاسم الثلاثي باللغة العربية' : 'Full Name'}
                  <span className="text-red-500">*</span>
                </label>
                <input 
                  type="text" 
                  name="full_name_arabic" 
                  value={formData.full_name_arabic} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                />
                {errors.full_name_arabic && <p className="text-red-500 text-xs">{errors.full_name_arabic}</p>}
              </div>
              {/* <div>
                <label className="block text-white mb-2 text-right">الاسم الثلاثي باللغة الانجليزية<span className="text-red-500">*</span></label>
                <input 
                  type="text" 
                  name="full_name_english" 
                  value={formData.full_name_english} 
                  onChange={handleChange} 
                  className="mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white text-right" 
                />
                {errors.full_name_english && <p className="text-red-500 text-xs">{errors.full_name_english}</p>}
              </div> */}
              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'جنس' : 'Gender'}
                  <span className="text-red-500">*</span>
                </label>
                <div className="mt-2 space-y-2">
                  {(language === 'ar' ? ['ذكر', 'أنثى', 'آخر'] : ['Male', 'Female', 'Other']).map((item) => (
                    <div key={item} className={`border border-white rounded-md p-2 inline-flex items-center ${language === 'ar' ? 'justify-end' : 'justify-start'} w-full text-white`}>
                      {language === 'ar' ? (
                        <>
                          <span className="ml-2">{item}</span>
                          <input
                            type="checkbox"
                            name="gender"
                            value={item}
                            onChange={handleChange}
                            checked={formData.gender === item}
                            className="form-checkbox text-blue-500 ml-2"
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            name="gender"
                            value={item}
                            onChange={handleChange}
                            checked={formData.gender === item}
                            className="form-checkbox text-blue-500 mr-2"
                          />
                          <span className="mr-2">{item}</span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {errors.gender && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.gender}</p>}
              </div>             
              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'العمر ( بالسنوات)' : 'Age (in years)'}
                  <span className="text-red-500">*</span>
                </label>
                <div className="mt-2 space-y-2">
                  {(language === 'ar' ? [
                    { value: '21-30', label: '21-30' },
                    { value: '31-40', label: '31-40' },
                    { value: 'أكثر من 40', label: 'أكثر من 40' }
                  ] : [
                    { value: '21-30', label: '21-30' },
                    { value: '31-40', label: '31-40' },
                    { value: 'More than 40', label: 'More than 40' }
                  ]).map((item) => (
                    <div key={item.value} className={`border border-white rounded-md p-2 inline-flex items-center ${language === 'ar' ? 'justify-end' : 'justify-start'} w-full text-white`}>
                      {language === 'ar' ? (
                        <>
                          <span className="ml-2">{item.label}</span>
                          <input
                            type="checkbox"
                            name="age_in_year"
                            value={item.value}
                            onChange={handleChange}
                            checked={formData.age_in_year.includes(item.value)}
                            className="form-checkbox text-blue-500 ml-2"
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            name="age_in_year"
                            value={item.value}
                            onChange={handleChange}
                            checked={formData.age_in_year.includes(item.value)}
                            className="form-checkbox text-blue-500 mr-2"
                          />
                          <span className="mr-2">{item.label}</span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {errors.age_in_year && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.age_in_year}</p>}
              </div>
              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'الجنسية' : 'Nationality'}
                  <span className="text-red-500">*</span>
                </label>
                <div className="mt-2 space-y-2 custom-dropdown-arrow">
                  {(language === 'ar' ? [
                    { value: 'لبناني / ة', label: 'لبناني / ة' },
                    { value: 'غير لبناني / ة', label: 'غير لبناني / ة' }
                  ] : [
                    { value: 'Lebanese', label: 'Lebanese' },
                    { value: 'Non-Lebanese', label: 'Non-Lebanese' }
                  ]).map((item) => (
                    <div key={item.value} className={`border border-white rounded-md p-2 inline-flex items-center ${language === 'ar' ? 'justify-end' : 'justify-start'} w-full text-white`}>
                      {language === 'ar' ? (
                        <>
                          <span className="ml-2">{item.label}</span>
                          <input
                            type="checkbox"
                            name="nationality"
                            value={item.value}
                            onChange={handleChange}
                            checked={formData.nationality.includes(item.value)}
                            className="form-checkbox text-blue-500 ml-2"
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            name="nationality"
                            value={item.value}
                            onChange={handleChange}
                            checked={formData.nationality.includes(item.value)}
                            className="form-checkbox text-blue-500 mr-2"
                          />
                          <span className="mr-2">{item.label}</span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {errors.nationality && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.nationality}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'القضاء( الاقامة الدائمة )' : 'Judiciary (Permanent Residence)'}
                  <span className="text-red-500">*</span>
                </label>
                <select 
                  name="permanent_residence" 
                  value={formData.permanent_residence} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-[#0a223f] text-white ${language === 'ar' ? 'text-right' : 'text-left'}`}
                >
                  <option value="">{language === 'ar' ? 'اختر القضاء' : 'Choose Judiciary'}</option>
                  {(language === 'ar' ? [
                    "خارج لبنان", "قضاء بيروت", "قضاء جبيل", "قضاء كسروان", "قضاء المتن", "قضاء عاليه", 
                    "قضاء الشوف", "قضاء طرابلس", "قضاء المنية - الضنية", "قضاء زغرتا - الزاوية", "قضاء البترون", 
                    "قضاء الكورة", "قضاء عكار", "قضاء بشري", "قضاء بعلبك", "قضاء الهرمل", "قضاء زحلة", 
                    "قضاء البقاع الغربي", "قضاء راشيا", "قضاء النبطية", "قضاء حاصبيا", "قضاء مرجعيون", 
                    "قضاء بنت جبيل", "قضاء صيدا", "قضاء صور", "قضاء جزين"
                  ] : [
                    "Outside Lebanon", "Beirut", "Jbeil", "Keserwan", "Metn", 
                    "Aley", "Chouf", "Tripoli", "Minieh-Danniyeh", "Zgharta", 
                    "Batroun", "Koura", "Akkar", "Bsharri", "Baalbek", 
                    "Hermel", "Zahle", "West Bekaa", "Rachaya", "Nabatiyeh", 
                    "Hasbaya", "Marjeyoun", "Bint Jbeil", "Saida", "Tyre", 
                    "Jezzine"
                  ]).map((district) => (
                    <option key={district} value={district}>{district}</option>
                  ))}
                </select>
                {errors.permanent_residence && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.permanent_residence}</p>}
              </div>

              {/* <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'المهنة الحالية' : 'Current Occupation'}
                  <span className="text-red-500">*</span>
                </label>
                <div className="mt-2 space-y-2">
                  {[
                    'Client servicing Manager', 
                    'Client Servicing Director', 
                    'Managing Director', 
                    'General Manager', 
                    'Brand Manager', 
                    'Sales Manager', 
                    'Trade Manager', 
                    'Supply Chain Manager', 
                    'Strategic Planner', 
                    'Creative Director', 
                    'Art Director', 
                    'Motion Graphic Designer', 
                    'Graphic Designer', 
                    'Conceptualizer', 
                    '3D Designer', 
                    'AutoCAD Specialist', 
                    'UI Designer', 
                    'UX Designer', 
                    'Web Designer', 
                    'Web Developer', 
                    'Marketing Manager', 
                    'Marketing Executive', 
                    'Business Owner/CEO', 
                    'SEO Search Engine Optimizer', 
                    'META Specialist'
                  ].map((item) => (
                    <div 
                      key={item} 
                      className={`border border-white rounded-md p-2 inline-flex items-center ${language === 'ar' ? 'justify-end' : 'justify-start'} w-full text-white`}
                    >
                      {language === 'ar' ? (
                        <>
                          <span className="ml-3">{item}</span>
                          <input 
                            type="checkbox" 
                            name="current_occupation" 
                            value={item} 
                            onChange={handleChange} 
                            className="form-checkbox text-blue-500 ml-2" 
                          />
                        </>
                      ) : (
                        <>
                          <input 
                            type="checkbox" 
                            name="current_occupation" 
                            value={item} 
                            onChange={handleChange} 
                            className="form-checkbox text-blue-500 mr-2" 
                          />
                          <span className="mr-3">{item}</span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {errors.current_occupation && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.current_occupation}</p>}
              </div> */}

              <div className="relative" ref={dropdownRef}>
              <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : ''}`}>Select your Profession</label>
                <div
                  className={`border border-white rounded-md p-2 text-white cursor-pointer flex justify-between items-center ${language === 'ar' ? 'flex-row-reverse' : ''}`}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <span>
                    {formData.current_occupation.length > 0
                      ? formData.current_occupation.join(', ')
                      : 'Choose Profession'}
                  </span>
                  <span className="ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </span>
                </div>
                {isDropdownOpen && (
                  <div className="absolute mt-1 w-full bg-[#0a223f] border border-white rounded-md z-10 max-h-60 overflow-y-auto custom-scrollbar">
                     {[
                        {
                          category: 'Leadership & Management',
                          current_occupation: [
                            'Chief Executive Officer (CEO)',
                            'Chief Operating Officer (COO)',
                            'Chief Marketing Officer (CMO)',
                            'Chief Digital Officer (CDO)',
                            'Managing Director (MD)',
                            'Operations Director (CD)',
                            'Agency Director (AD)',
                            'Partner',
                            'Vice President of Marketing (VPM)',
                            'Vice President of Digital Strategy (VPMS)',
                            'Director of Client Services',
                            'Director of Marketing',
                            'Director of Operations',
                            'Director of Business Development',
                            'Digital Marketing Manager',
                            'Account Manager',
                            'Account Executive',
                            'Client Success Manager',
                            'Project Manager',
                          ],
                        },
                        {
                          category: 'Strategy & Planning',
                          current_occupation: [
                            'Digital Strategy Consultant',
                            'Marketing Strategist',
                            'Brand Strategist',
                            'SEO Strategy Manager',
                            'Social Media Strategist',
                            'Content Strategist',
                            'PPC Strategist',
                            'Media Planner',
                            'Growth Marketing Manager',
                            'Campaign Manager',
                          ],
                        },
                        {
                          category: 'Creative',
                          current_occupation: [
                            'Creative Director',
                            'Graphic Designer',
                            'Web Designer',
                            'UI/UX Designer',
                            'Copywriter',
                            'Content Writer',
                            'Content Creator',
                            'Social Media Content Designer',
                            'Video Editor',
                            'Motion Graphics Designer',
                            'Photographer',
                          ],
                        },
                        {
                          category: 'Analytics & Research',
                          current_occupation: [
                            'Data Analyst',
                            'Digital Marketing Analyst',
                            'SEO Analyst',
                            'Web Analyst',
                            'Conversion Rate Optimization (CRO) Specialist',
                            'Market Research Analyst',
                            'Audience Insights Manager',
                            'Business Intelligence Analyst',
                          ],
                        },
                        {
                          category: 'Search Engine Optimization (SEO)',
                          current_occupation: [
                            'SEO Specialist',
                            'SEO Manager',
                            'Technical SEO Specialist',
                            'Local SEO Specialist',
                            'Link Building Specialist',
                          ],
                        },
                        {
                          category: 'Paid Media & Advertising',
                          current_occupation: [
                            'PPC Specialist',
                            'SEM Specialist',
                            'Digital Advertising Manager',
                            'A/B Testing Specialist',
                            'Ad Operations Specialist',
                            'Programmatic Media Buyer',
                          ],
                        },
                        {
                          category: 'Social Media',
                          current_occupation: [
                            'Social Media Manager',
                            'Social Media Coordinator',
                            'Social Media Analyst',
                            'Community Manager',
                            'Influencer Marketing Manager',
                            'Blogger Marketing Manager',
                          ],
                        },
                        {
                          category: 'Email Marketing',
                          current_occupation: [
                            'Email Marketing Specialist',
                            'Email Campaign Manager',
                            'CRM Specialist',
                            'Marketing Automation Specialist',
                          ],
                        },
                        {
                          category: 'Freelance & Contract Positions',
                          current_occupation: [
                            'Freelance Digital Marketer',
                            'Consultant',
                            'Independent Contractor (specific skill-based roles)',
                            'Emerging Roles',
                            'Voice Search Optimization Specialist',
                            'AI & Automation Specialist in Marketing',
                            'Chatbot Marketing Specialist',
                            'Web 3.0 Marketing Specialist',
                            'Metaverse Marketing Manager',
                          ],
                        },
                        {
                          category: 'Interns and Entry-Level Positions',
                          current_occupation: [
                            'Digital Marketing Intern',
                            'SEO Intern',
                            'Social Media Intern',
                            'Content Marketing Intern',
                            'Data Analytics Intern',
                          ],
                        },
                        {
                          category: 'Digital Marketing Agencies',
                          current_occupation: [
                            'Digital Marketing Manager',
                            'SEO Specialist',
                            'Social Media Manager',
                            'Content Marketing Strategist',
                            'PPC Specialist',
                            'Email Marketing Specialist',
                            'Brand Strategist',
                            'Digital Account Manager',
                          ],
                        },
                        {
                          category: 'E-commerce',
                          current_occupation: [
                            'E-commerce Manager',
                            'Product Listing Specialist',
                            'Online Merchandising Specialist',
                            'Customer Experience Manager',
                            'Digital Marketing Analyst',
                            'Conversion Rate Optimization Specialist',
                          ],
                        },
                        {
                          category: 'Technology and Software Development',
                          current_occupation: [
                            'Product Marketing Manager',
                            'User Experience (UX) Designer',
                            'User Interface (UI) Designer',
                            'Web Analytics Specialist',
                            'Growth Hacker',
                            'Software Marketing Specialist',
                          ],
                        },
                        {
                          category: 'Media and Publishing',
                          current_occupation: [
                            'Digital Content Editor',
                            'Journalist (Digital/Multimedia)',
                            'Social Media Producer',
                            'SEO Editor',
                            'Multimedia Designer',
                            'Digital Advertising Coordinator',
                          ],
                        },
                        {
                          category: 'Telecommunications',
                          current_occupation: [
                            'Digital Communications Specialist',
                            'Brand Manager',
                            'Customer Engagement Manager',
                            'Market Research Analyst',
                            'Digital Strategy Consultant',
                          ],
                        },
                        {
                          category: 'Entertainment and Gaming',
                          current_occupation: [
                            'Digital Brand Manager',
                            'Social Media Community Manager',
                            'Gaming Marketing Specialist',
                            'Content Strategist for Gaming Platforms',
                            'Digital Campaign Manager',
                            'Influencer Marketing Coordinator',
                          ],
                        },
                        {
                          category: 'Healthcare',
                          current_occupation: [
                            'Digital Health Marketing Specialist',
                            'Patient Engagement Specialist',
                            'Healthcare Social Media Administrator',
                            'Digital Content Manager for Health Services',
                            'Health Communication Specialist',
                          ],
                        },
                        {
                          category: 'Education',
                          current_occupation: [
                            'Digital Enrollment Manager',
                            'E-Learning Marketing Manager',
                            'Social Media Community Manager for Educational Institutions',
                            'Digital Content Strategist for Education',
                            'Educational Technology Specialist',
                          ],
                        },
                        {
                          category: 'Finance and Insurance',
                          current_occupation: [
                            'Digital Marketing Analyst (Financial Services)',
                            'Online Reputation Manager',
                            'Digital Compliance Specialist',
                            'Fintech Marketing Specialist',
                            'Content Strategist for Financial Services',
                          ],
                        },
                        {
                          category: 'Non-Profit Organizations',
                          current_occupation: [
                            'Digital Fundraising Coordinator',
                            'Social Media Manager (Non-Profit)',
                            'Community Engagement Specialist',
                            'Digital Advocacy Manager',
                            'Content Creator for Social Impact',
                          ],
                        },
                        {
                          category: 'Public Relations',
                          current_occupation: [
                            'Digital PR Specialist',
                            'Social Media Specialist',
                            'Media Relations Coordinator',
                            'Crisis Communication Specialist',
                            'Content Marketing Manager (PR focus)',
                          ],
                        },
                        {
                          category: 'Influencer Marketing',
                          current_occupation: [
                            'Influencer Marketing Manager',
                            'Brand Partnerships Coordinator',
                            'Campaign Analyst for Influencer Strategies',
                            'Social Media Influencer Relations Manager',
                          ],
                        },
                        {
                          category: 'B2B',
                          current_occupation: [
                            'Syndicates',
                            'NGO',
                            'Ministeries',
                            'Municipality',
                            'META Specialist (Instagram – Facebook)',
                            'TikTok',
                            'Linkedin',
                            'X',
                          ],
                        }].map((group) => (
                            <div key={group.category} className="p-2">
                                <p className={`font-bold text-white ${language === 'ar' ? 'text-right' : ''}`}>{group.category}</p>
                                {group.current_occupation.map((role) => (
                                <div className={`flex items-center mt-2 ${language === 'ar' ? 'flex-row-reverse' : ''}`}>
                                <input
                                    type="checkbox"
                                    name="current_occupation"
                                    value={role}
                                    checked={formData.current_occupation.includes(role)}
                                    onChange={handleChange}
                                    className={`form-checkbox text-blue-500 ${language === 'ar' ? 'ml-2' : 'mr-2'}`}
                                  />
                                <label className={`text-white ${language === 'ar' ? 'text-right' : ''}`}>{role}</label>
                                </div>
                              ))}
                            </div>
                          ))}
                    </div>
                )}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'اسم المؤسسة (في حال عدم العمل في مؤسسة اكتب عمل حر)' : 'Institute Name (If not working in an institution, write Freelancer)'}
                  <span className="text-red-500">*</span>
                </label>
                <input 
                  type="text" 
                  name="institute_name" 
                  value={formData.institute_name} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                />
                {errors.institute_name && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.institute_name}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'الشهادات العلمية' : 'Academic Degrees'}
                  <span className="text-red-500">*</span>
                </label>
                <div className="mt-2 space-y-2">
                  {(language === 'ar' ? 
                    ['باكالوريوس', 'ماجستير', 'دكتوراه', 'مهني', 'دورات تدريبية', 'دبلوم'] : 
                    ['Bachelor\'s', 'Master\'s', 'PhD', 'Vocational', 'Training Courses', 'Diploma']
                  ).map((item) => (
                    <div 
                      key={item} 
                      className={`border border-white rounded-md p-2 inline-flex items-center ${language === 'ar' ? 'justify-end' : 'justify-start'} w-full text-white`}
                    >
                      {language === 'ar' ? (
                        <>
                          <span className="mr-2">{item}</span>
                          <input
                            type="checkbox"
                            name="certificate"
                            value={item}
                            onChange={handleChange}
                            checked={formData.certificate.includes(item)}
                            className="form-checkbox text-blue-500"
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            name="certificate"
                            value={item}
                            onChange={handleChange}
                            checked={formData.certificate.includes(item)}
                            className="form-checkbox text-blue-500 mr-2"
                          />
                          <span className="mr-2">{item}</span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {errors.certificate && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.certificate}</p>}
              </div>



              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'التخصص العلمي الأساسي' : 'Main Scientific Specialization'}
                  <span className="text-red-500">*</span>
                </label>
                <input 
                  type="text" 
                  name="specialization" 
                  value={formData.specialization} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                />
                {errors.specialization && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.specialization}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'عدد سنوات الخبرة' : 'Number of Years of Experience'}
                  <span className="text-red-500">*</span>
                </label>
                <select 
                  name="experience" 
                  value={formData.experience} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-[#0a223f] text-white ${language === 'ar' ? 'text-right' : 'text-left'}`}
                >
                  <option value="">{language === 'ar' ? 'اختر الخبرة' : 'Choose Experience'}</option>
                  <option value="1-2">1-2</option>
                  <option value="2-3">2-3</option>
                  <option value="3-4">3-4</option>
                  <option value="4-5">4-5</option>
                  <option value="5-10">5-10</option>
                  <option value="أكثر من عشر سنوات">{language === 'ar' ? 'أكثر من عشر سنوات' : 'More than Ten Years'}</option>
                </select>
                {errors.experience && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.experience}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 
                    'ما هي الشروط التي تتميز بها ويمكنك تأمينها من اللائحة التالية(مطلوب 3 من 8 على الأقل)' :
                    'What are the conditions that are distinguished and that you can secure from the following list (at least 3 of 8 are required)?'}
                  <span className="text-red-500">*</span>
                </label>
                <div className={`mt-2 space-y-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {[
                    language === 'ar' ? 
                    'ان يكون متخصص في مجال التسويق الرقمي او الدعاية والاعلان ويتمتع بخبرة في هذا المجال' :
                    'Must be a specialist in the field of digital media or marketing or advertising and have experience in this field',
                    language === 'ar' ? 
                    'أن يكون منسق التسويق الرقمي في القطاع التجاري مع خبرة عملية تتجاوز الثلاث سنوات' :
                    'To be an expert in the field of marketing strategies in the twenty-first century with practical experience exceeding three years',
                    language === 'ar' ? 
                    'ان يكون خبير في مجال استراتيجيات التسويق في القرن الحادي والعشرين مع خبرة عملية تتجاوزالثلاث سنوات' :
                    'To be an expert in the digital / media & marketing sector with a certificate of experience exceeding three years',
                    language === 'ar' ? 
                    'أن يكون خبير في قطاع التسويق الرقمي مع شهادة خبرة تتجاوز الثلاث سنوات' :
                    'Expert in digital marketing with a certificate of experience exceeding three years',
                    language === 'ar' ? 
                    'ان يتمتع بأكثر من ثلاث سنوات خبرة على الاقل في مجال التسويق الرقمي او الدعاية والاعلان' :
                    'Must have at least more than three years of experience in the field of digital media, marketing or advertising',
                    language === 'ar' ? 
                    'ان يكون قد اتم برنامج التدريب بنجاح المعتمد من النقابة' :
                    'Must have successfully completed the training program approved by the Syndicate',
                    language === 'ar' ? 
                    'ان يكون قد اتم دورات تدريبية بالتسويق الرقمي مع جهة معتمدة او من النقابة' :
                    'Must have completed training courses in digital media & marketing with an accredited body or from the syndicate',
                    language === 'ar' ? 
                    'تقدم بافادة خبرة عملية من مؤسسة تقدم حلول في التسويق الرقمي محلية او دولية' :
                    'Provided by benefiting from practical experience from an organization that provides solutions in digital media, marketing or advertising, local or international',
                  ].map((item) => (
                    <div key={item} className={`border border-white rounded-md p-2 inline-flex items-center justify-${language === 'ar' ? 'end' : 'start'} w-full text-white ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                      {language === 'ar' && (
                        <>
                          <span className="ml-2">{item}</span>
                          <input
                            type="checkbox"
                            name="condition_question"
                            value={item}
                            onChange={handleChange}
                            checked={formData.condition_question.includes(item)}
                            className="form-checkbox text-blue-500 ml-2"
                          />
                        </>
                      )}
                      {language !== 'ar' && (
                        <>
                          <input
                            type="checkbox"
                            name="condition_question"
                            value={item}
                            onChange={handleChange}
                            checked={formData.condition_question.includes(item)}
                            className="form-checkbox text-blue-500 ml-2"
                          />
                          <span className="ml-2">{item}</span>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                {errors.condition_question && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.condition_question}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 
                    'هل أنت منتسب/ة لأي نقابة أخرى ؟' : 
                    'Are you a member of any other union?'}
                  <span className="text-red-500">*</span>
                </label>
                <div className={`mt-2 space-y-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  <div className={`border border-white rounded-md p-2 inline-flex items-center ${language === 'ar' ? 'justify-end' : 'justify-start'} w-full text-white`}>
                    {language === 'ar' ? 
                      <>
                        <span className="ml-2">نعم</span>
                        <input type="radio" name="is_union_member" value="1" onChange={handleChange} checked={formData.is_union_member === '1'} className="form-radio text-blue-500 ml-2" />
                      </> : 
                      <>
                        <input type="radio" name="is_union_member" value="1" onChange={handleChange} checked={formData.is_union_member === '1'} className="form-radio text-blue-500 ml-2" />
                        <span className="ml-2">Yes</span>
                      </>
                    }
                  </div>
                  <div className={`border border-white rounded-md p-2 inline-flex items-center ${language === 'ar' ? 'justify-end' : 'justify-start'} w-full text-white`}>
                    {language === 'ar' ? 
                      <>
                        <span className="ml-2">كلا</span>
                        <input type="radio" name="is_union_member" value="0" onChange={handleChange} checked={formData.is_union_member === '0'} className="form-radio text-blue-500 ml-2" />
                      </> : 
                      <>
                        <input type="radio" name="is_union_member" value="0" onChange={handleChange} checked={formData.is_union_member === '0'} className="form-radio text-blue-500 ml-2" />
                        <span className="ml-2">No</span>
                      </>
                    }
                  </div>
                </div>
                {errors.is_union_member && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.is_union_member}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'ما هي النقابة التي تنتسب اليها حالياً' : 'Which union are you currently a member of?'}
                </label>
                <input 
                  type="text" 
                  name="union_belong" 
                  value={formData.union_belong} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                />
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 'رقم الهاتف المحمول' : 'Mobile Phone Number'}
                  <span className="text-red-500">*</span>
                </label>
                <input 
                  type="text" 
                  name="phone_no" 
                  value={formData.phone_no} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                />
                {errors.phone_no && <p className="text-red-500 text-xs">{errors.phone_no}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  LinkedIn
                  <span className="text-red-500">*</span>
                </label>
                <input 
                  type="url" 
                  name="linkedin" 
                  value={formData.linkedin} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                />
                {errors.linkedin && <p className="text-red-500 text-xs">{errors.linkedin}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  Instagram
                  <span className="text-red-500">*</span>
                </label>
                <input 
                  type="url" 
                  name="instagram" 
                  value={formData.instagram} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                />
                {errors.instagram && <p className="text-red-500 text-xs">{errors.instagram}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  Facebook
                  <span className="text-red-500">*</span>
                </label>
                <input 
                  type="url" 
                  name="facebook" 
                  value={formData.facebook} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                />
                {errors.facebook && <p className="text-red-500 text-xs">{errors.facebook}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  YouTube Channel
                </label>
                <input 
                  type="url" 
                  name="youtube_channel" 
                  value={formData.youtube_channel} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                />
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 
                    'اسم مرجع يعرف عنك من النقابة أو قطاع الاختصاص' : 
                    'Name of a reference who knows about you from the union or the field of expertise'}
                  <span className="text-red-500">*</span>
                </label>
                <input 
                  type="text" 
                  name="refrence_name" 
                  value={formData.refrence_name} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                />
                {errors.refrence_name && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.refrence_name}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 
                    'رقم هاتف المرجع' : 
                    'Reference Phone Number'}
                  <span className="text-red-500">*</span>
                </label>
                <input 
                  type="text" 
                  name="refrence_phone_no" 
                  value={formData.refrence_phone_no} 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                />
                {errors.refrence_phone_no && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.refrence_phone_no}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 
                    'صورة شخصية تصلح لبطاقة تعريف' : 
                    'A Personal Photo suitable for an Identification Card'}
                  <span className="text-red-500">*</span>
                </label>
                <input 
                  type="file" 
                  name="image" 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                  accept="image/*"
                />
                {errors.image && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.image}</p>}
              </div>

              <div>
                <label className={`block text-white mb-2 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                  {language === 'ar' ? 
                    'أرفع الملفات التالية: صورة عن الهوية أو اخراج القيد، صورة عن السجل العدلي لا يتخطى الشهر من الاصدار، صورة شخصية تصلح لبطاقة تعريف، والسيرة الذاتية' :
                    'Upload the following files: A copy of your identity card or registration certificate, a copy of your criminal record no later than a month after issuance, and a CV'}
                  <span className="text-red-500">*</span>
                </label>
                <input 
                  type="file" 
                  name="attach_file" 
                  onChange={handleChange} 
                  className={`mt-1 block w-full rounded-md border border-white shadow-sm p-2 bg-transparent text-white ${language === 'ar' ? 'text-right' : 'text-left'}`} 
                  multiple
                />
                {errors.attach_file && <p className={`text-red-500 text-xs ${language === 'ar' ? 'text-right' : 'text-left'}`}>{errors.attach_file}</p>}
              </div>

              <div className="mt-4">
                <button 
                  type="submit" 
                  className="w-full bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600" 
                  disabled={isLoading}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <LoadingOverlay isActive={isLoading} />
    </DashboardLayout>
  );
}