import React, { useEffect, useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import api from '../utils/api';
import { FaEye, FaEdit } from 'react-icons/fa';
import { useTable } from 'react-table';
import LoadingOverlay from '../components/loader/LoadingOverlay';
import { toast, Toaster } from 'react-hot-toast';
import ApplicantDetailsModal from '../components/ApplicantDetailsModal';
import ChangeStatusModal from '../components/ChangeStatusModal';

export default function DashboardListingApplicants() {
  const [applicants, setApplicants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(true);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('Accepted'); 
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'full_name_arabic',
      },
      {
        Header: 'Age',
        accessor: 'age_in_year',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <FaEye
              className="cursor-pointer text-blue-500"
              onClick={() => handleViewDetails(row.original)}
            />
            {row.original.status !== 'Accepted' && row.original.status !== 'Rejected' && (
              <FaEdit
                className="cursor-pointer text-green-500"
                onClick={() => handleChangeStatus(row.original)}
              />
            )}
          </div>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => applicants, [applicants]);

  const tableInstance = useTable({ columns, data });

  useEffect(() => {
    fetchApplicants(pagination.currentPage);
  }, [pagination.currentPage]);

  const fetchApplicants = async (page) => {
    setIsApiLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user?.token;

    try {
      const response = await api.get(`/api/getAppList?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.success) {
        setApplicants(response.data.data);
        setPagination({
          currentPage: response.data.current_page,
          lastPage: response.data.last_page,
        });
      } else {
        console.error('Error fetching applicants:', response.message);
        toast.error('Failed to load applicants.');
      }
    } catch (error) {
      console.error('Error fetching applicants:', error);
      toast.error('An error occurred while fetching applicants.');
    } finally {
      setIsApiLoading(false);
    }
  };

  const handleViewDetails = (applicant) => {
    setSelectedApplicant(applicant);
    setModalIsOpen(true);
  };

  const handleChangeStatus = (applicant) => {
    setSelectedApplicant(applicant);
    setSelectedStatus('Accepted');
    setConfirmationModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedApplicant(null);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
    setSelectedApplicant(null);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const confirmChangeStatus = async () => {
    setIsLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = user?.token;

      const response = await api.post(
        '/api/changeAppStaus',
        {
          id: selectedApplicant.id,
          status: selectedStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.success) {
        toast.success('Status changed successfully');
        setApplicants((prevApplicants) =>
          prevApplicants.map((applicant) =>
            applicant.id === selectedApplicant.id ? { ...applicant, status: selectedStatus } : applicant
          )
        );
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
      closeConfirmationModal();
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <DashboardLayout>
      <h1 className="text-3xl font-bold mb-8 text-center text-white">
        Admin Dashboard Listing Applicants
      </h1>
      <div className="p-4">
        <div className="overflow-auto max-h-[380px]">
          <table
            {...getTableProps()}
            className="min-w-full bg-white shadow-md rounded-lg"
          >
            <thead className="bg-gray-100">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="py-2 px-4 text-left text-black"
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="border-t">
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="py-2 px-4 text-black"
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }))}
            disabled={pagination.currentPage === 1}
            className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
          >
            Previous
          </button>
          <span className="text-white">
            Page {pagination.currentPage} of {pagination.lastPage}
          </span>
          <button
            onClick={() => setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }))}
            disabled={pagination.currentPage === pagination.lastPage}
            className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
          >
            Next
          </button>
        </div>
      </div>
      <LoadingOverlay isActive={isApiLoading || isLoading} />

      {selectedApplicant && (
        <ApplicantDetailsModal
          isOpen={modalIsOpen}
          applicant={selectedApplicant}
          onRequestClose={closeModal}
        />
      )}
      {selectedApplicant && (
        <ChangeStatusModal
          isOpen={confirmationModalIsOpen}
          selectedStatus={selectedStatus}
          onRequestClose={closeConfirmationModal}
          onStatusChange={handleStatusChange}
          onConfirm={confirmChangeStatus}
          isLoading={isLoading}
        />
      )}
      <Toaster />
    </DashboardLayout>
  );
}
