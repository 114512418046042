/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

export const API_BASE_URL = " https://api.dmms.io";

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const get = async (endpoint, config = {}) => {
  const response = await api.get(endpoint, config);
  return response.data;
};

export const post = async (endpoint, data, config = {}) => {
  const response = await api.post(endpoint, data, config);
  return response.data;
};

export const put = async (endpoint, data, config = {}) => {
  const response = await api.put(endpoint, data, config);
  return response.data;
};

export const del = async (endpoint, config = {}) => {
  const response = await api.delete(endpoint, config);
  return response.data;
};

export default {
  get,
  post,
  put,
  del,
};
