import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';

export default function ApplicationStatusPage({ status }) {
  const navigate = useNavigate();
  const isAccepted = status === 'Accepted';

  const handleLogout = () => {
    localStorage.clear(); 
    navigate('/login');
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#0a223f] text-white">
      <div className="text-center">
        <div className="mb-8 animate-pulse">
          {isAccepted ? (
            <FiCheckCircle 
              className="w-24 h-24 mx-auto text-green-500 transition-transform duration-300 ease-in-out transform hover:scale-110" 
            />
          ) : (
            <FiXCircle 
              className="w-24 h-24 mx-auto text-red-500 transition-transform duration-300 ease-in-out transform hover:scale-110" 
            />
          )}
        </div>
        <h1 className="text-4xl font-bold mb-4 animate-fadeIn">
          {isAccepted ? 'Congratulations!' : 'Application Rejected'}
        </h1>
        <p className="text-lg mb-8 animate-fadeIn delay-1">
          {isAccepted 
            ? 'Your application has been accepted. Welcome aboard!' 
            : 'Unfortunately, your application has been rejected. Please contact support for more information.'
          }
        </p>
        <button
          onClick={handleLogout}
          className="bg-red-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-red-600 transition-colors transform hover:scale-105"
        >
          Logout
        </button>
      </div>
    </div>
  );
}